import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import axios from 'axios';
//import Keyboard from "react-simple-keyboard";

// Instead of the default import, you can also use this:
// import { KeyboardReact as Keyboard } from "react-simple-keyboard"

const root = ReactDOM.createRoot(document.getElementById('root'));
document.body.style.backgroundColor = "#ff0077";
document.body.style.color = "white";
document.body.style.textAlign = "center";
let host;
let password;
root.render(
  <React.StrictMode>
     <div>
       <h1>GlobalKeyboard</h1>
       <p>Let us connect to your GlobalShut computer. This application will act as a global keyboard</p>
       <form onSubmit={function(e) {
         e.preventDefault();
        // console.error("The password that you have entered is " + document.getElementById("password").value.toString());
         axios.post(`${(document.getElementById("host").value).toString()}/verify_password`, {
          password: `${(document.getElementById("password").value).toString()}`,
        })
        .then(function (response) {
          console.log(response);
          host = document.getElementById("host").value.toString();
          password = document.getElementById("password").value.toString();
          if(response.data.verified === "yes") {
             openTheKeyboard((document.getElementById("host").value).toString(), (document.getElementById("password").value).toString());
          }else{
             alert("Sorry, But the password is Invalid! Failed to open GlobalKeyboard!");
          }
        })
        .catch(function (error) {
          console.log(error);
        });
       }}>
          <input id='host' placeholder='Link to PC' required></input>
          <br></br>
          <br></br>
          <input id='password' type='password' placeholder='********************' required></input>
          <br></br>
          <br></br>
          <button className='button' type='submit'>Connect</button>
       </form>
       <br></br>
       <h1>Other methods for connection</h1>
       <p>Currently there is no other method for connection to your PC. You have to provide the password using which you started the GlobaLShut app on your PC.</p>
       <a href='https://site.globalshut.namishkumar.in'><button>Download GlobalShut</button></a>
     </div>
  </React.StrictMode>
);
function openTheKeyboard(host, password) {
  root.render(
     <React.StrictMode>
      <div className="keyboard">
  <div className="row">
    <input id='caps' value="yes" hidden></input>
    <form onSubmit={function(e) {
       e.preventDefault();
       const caps = document.getElementById("caps").value;
       if(caps === "yes") {
         sendThatKey("Q");
       }else{
        sendThatKey("q");
       }
    }}>
    <button type='submit' className="buttono" id='q' data-value="q">Q</button>
    </form>
    <form onSubmit={function(e) {
       e.preventDefault();
       const caps = document.getElementById("caps").value;
       if(caps === "yes") {
         sendThatKey("W");
       }else{
        sendThatKey("w");
       }
    }}>
    <button type='submit' className="buttono" id='w' data-value="w">W</button>
    </form>
    <form onSubmit={function(e) {
       e.preventDefault();
       const caps = document.getElementById("caps").value;
       if(caps === "yes") {
         sendThatKey("E");
       }else{
        sendThatKey("e");
       }
    }}>
    <button type='submit' className="buttono" id='e' data-value="e">E</button>
    </form>
    <form onSubmit={function(e) {
       e.preventDefault();
       const caps = document.getElementById("caps").value;
       if(caps === "yes") {
         sendThatKey("R");
       }else{
        sendThatKey("r");
       }
    }}>
    <button type='submit' className="buttono" id='r' data-value="r">R</button>
    </form>
    <form onSubmit={function(e) {
       e.preventDefault();
       const caps = document.getElementById("caps").value;
       if(caps === "yes") {
         sendThatKey("T");
       }else{
        sendThatKey("t");
       }
    }}>
    <button type='submit' className="buttono" id='t' data-value="t">T</button>
    </form>
    <form onSubmit={function(e) {
       e.preventDefault();
       const caps = document.getElementById("caps").value;
       if(caps === "yes") {
         sendThatKey("Y");
       }else{
        sendThatKey("y");
       }
    }}>
    <button type='submit' className="buttono" id='y' data-value="y">Y</button>
    </form>
    <form onSubmit={function(e) {
       e.preventDefault();
       const caps = document.getElementById("caps").value;
       if(caps === "yes") {
         sendThatKey("U");
       }else{
        sendThatKey("u");
       }
    }}>
    <button type='submit' className="buttono" id='u' data-value="u">U</button>
    </form>
    <form onSubmit={function(e) {
       e.preventDefault();
       const caps = document.getElementById("caps").value;
       if(caps === "yes") {
         sendThatKey("I");
       }else{
        sendThatKey("i");
       }
    }}>
    <button type='submit' className="buttono" id='i' data-value="i">I</button>
    </form>
    <form onSubmit={function(e) {
       e.preventDefault();
       const caps = document.getElementById("caps").value;
       if(caps === "yes") {
         sendThatKey("O");
       }else{
        sendThatKey("o");
       }
    }}>
    <button type='submit' className="buttono" id='o' data-value="o">O</button>
    </form>
    <form onSubmit={function(e) {
       e.preventDefault();
       const caps = document.getElementById("caps").value;
       if(caps === "yes") {
         sendThatKey("P");
       }else{
        sendThatKey("p");
       }
    }}>
    <button type='submit' className="buttono" id='p' data-value="p">P</button>
    </form>
  </div>
  <div className="row">
  <form onSubmit={function(e) {
       e.preventDefault();
       const caps = document.getElementById("caps").value;
       if(caps === "yes") {
         sendThatKey("A");
       }else{
        sendThatKey("a");
       }
    }}>
    <button type='submit' className="buttono" id='a' data-value="a">A</button>
    </form>
    <form onSubmit={function(e) {
       e.preventDefault();
       const caps = document.getElementById("caps").value;
       if(caps === "yes") {
         sendThatKey("S");
       }else{
        sendThatKey("s");
       }
    }}>
    <button type='submit' className="buttono" id='s' data-value="s">S</button>
    </form>
    <form onSubmit={function(e) {
       e.preventDefault();
       const caps = document.getElementById("caps").value;
       if(caps === "yes") {
         sendThatKey("D");
       }else{
        sendThatKey("d");
       }
    }}>
    <button type='submit' className="buttono" id='d' data-value="d">D</button>
    </form>
    <form onSubmit={function(e) {
       e.preventDefault();
       const caps = document.getElementById("caps").value;
       if(caps === "yes") {
         sendThatKey("F");
       }else{
        sendThatKey("f");
       }
    }}>
    <button type='submit' className="buttono" id='f' data-value="f">F</button>
    </form>
    <form onSubmit={function(e) {
       e.preventDefault();
       const caps = document.getElementById("caps").value;
       if(caps === "yes") {
         sendThatKey("G");
       }else{
        sendThatKey("g");
       }
    }}>
    <button type='submit' className="buttono" id='g' data-value="g">G</button>
    </form>
    <form onSubmit={function(e) {
       e.preventDefault();
       const caps = document.getElementById("caps").value;
       if(caps === "yes") {
         sendThatKey("H");
       }else{
        sendThatKey("h");
       }
    }}>
    <button type='submit' className="buttono" id='h' data-value="h">H</button>
    </form>
    <form onSubmit={function(e) {
       e.preventDefault();
       const caps = document.getElementById("caps").value;
       if(caps === "yes") {
         sendThatKey("J");
       }else{
        sendThatKey("j");
       }
    }}>
    <button type='submit' className="buttono" id='j' data-value="j">J</button>
    </form>
    <form onSubmit={function(e) {
       e.preventDefault();
       const caps = document.getElementById("caps").value;
       if(caps === "yes") {
         sendThatKey("K");
       }else{
        sendThatKey("k");
       }
    }}>
    <button type='submit' className="buttono" id='k' data-value="k">K</button>
    </form>
    <form onSubmit={function(e) {
       e.preventDefault();
       const caps = document.getElementById("caps").value;
       if(caps === "yes") {
         sendThatKey("L");
       }else{
        sendThatKey("l");
       }
    }}>
    <button type='submit' className="buttono" id='l' data-value="l">L</button>
    </form>
  </div>
  <div className="row">
  <form onSubmit={function(e) {
       e.preventDefault();
       const caps = document.getElementById("caps").value;
       if(caps === "yes") {
         sendThatKey("Z");
       }else{
        sendThatKey("z");
       }
    }}>
    <button type='submit' className="buttono" id='z' data-value="z">Z</button>
    </form>
    <form onSubmit={function(e) {
       e.preventDefault();
       const caps = document.getElementById("caps").value;
       if(caps === "yes") {
         sendThatKey("X");
       }else{
        sendThatKey("x");
       }
    }}>
    <button type='submit' className="buttono" id='x' data-value="x">X</button>
    </form>
    <form onSubmit={function(e) {
       e.preventDefault();
       const caps = document.getElementById("caps").value;
       if(caps === "yes") {
         sendThatKey("C");
       }else{
        sendThatKey("c");
       }
    }}>
    <button type='submit' className="buttono" id='c' data-value="c">C</button>
    </form>
    <form onSubmit={function(e) {
       e.preventDefault();
       const caps = document.getElementById("caps").value;
       if(caps === "yes") {
         sendThatKey("V");
       }else{
        sendThatKey("v");
       }
    }}>
    <button type='submit' className="buttono" id='v' data-value="v">V</button>
    </form>
    <form onSubmit={function(e) {
       e.preventDefault();
       const caps = document.getElementById("caps").value;
       if(caps === "yes") {
         sendThatKey("B");
       }else{
        sendThatKey("b");
       }
    }}>
    <button type='submit' className="buttono" id='b' data-value="b">B</button>
    </form>
    <form onSubmit={function(e) {
       e.preventDefault();
       const caps = document.getElementById("caps").value;
       if(caps === "yes") {
         sendThatKey("N");
       }else{
        sendThatKey("n");
       }
    }}>
    <button type='submit' className="buttono" id='n' data-value="n">N</button>
    </form>
    <form onSubmit={function(e) {
       e.preventDefault();
       const caps = document.getElementById("caps").value;
       if(caps === "yes") {
         sendThatKey("M");
       }else{
        sendThatKey("m");
       }
    }}>
    <button type='submit' className="buttono" id='m' data-value="m">M</button>
    </form>
  </div>
  <div className="row">
  <form onSubmit={function(e) {
       e.preventDefault();
       const caps = document.getElementById("caps").value;
       sendThatKey("-");
    }}>
    <button type='submit' className="buttono" id='-' data-value="-">-</button>
    </form>
    <form onSubmit={function(e) {
       e.preventDefault();
       const caps = document.getElementById("caps").value;
       sendThatKey("=");
    }}>
    <button type='submit' className="buttono" id='=' data-value="=">=</button>
    </form>
    <form onSubmit={function(e) {
       e.preventDefault();
       const caps = document.getElementById("caps").value;
       sendThatKey("space");
    }}>
    <button type='submit' className="buttono" id='space' data-value="space">space</button>
    </form>
  </div>
</div>
<div>
   <br></br>
   <h1>Keyboard Options</h1>
   <form onSubmit={function(e) {
      e.preventDefault();
      if(document.getElementById("caps").value === "yes") {
        document.getElementById("q").innerHTML = "q";
        document.getElementById("w").innerHTML = "w";
        document.getElementById("e").innerHTML = "e";
        document.getElementById("r").innerHTML = "r";
        document.getElementById("t").innerHTML = "t";
        document.getElementById("y").innerHTML = "y";
        document.getElementById("u").innerHTML = "u";
        document.getElementById("i").innerHTML = "i";
        document.getElementById("o").innerHTML = "o";
        document.getElementById("p").innerHTML = "p";
        document.getElementById("a").innerHTML = "a";
        document.getElementById("s").innerHTML = "s";
        document.getElementById("d").innerHTML = "d";
        document.getElementById("f").innerHTML = "f";
        document.getElementById("g").innerHTML = "g";
        document.getElementById("h").innerHTML = "h";
        document.getElementById("j").innerHTML = "j";
        document.getElementById("k").innerHTML = "k";
        document.getElementById("l").innerHTML = "l";
        document.getElementById("z").innerHTML = "z";
        document.getElementById("x").innerHTML = "x";
        document.getElementById("c").innerHTML = "c";
        document.getElementById("v").innerHTML = "v";
        document.getElementById("b").innerHTML = "b";
        document.getElementById("n").innerHTML = "n";
        document.getElementById("m").innerHTML = "m";
        document.getElementById("caps").value = "no";
      }else if( document.getElementById("caps").value === "no"){
        document.getElementById("q").innerHTML = document.getElementById("q").innerHTML.toString().toUpperCase();
        document.getElementById("w").innerHTML = document.getElementById("w").innerHTML.toString().toUpperCase();
        document.getElementById("e").innerHTML = document.getElementById("e").innerHTML.toString().toUpperCase();
        document.getElementById("r").innerHTML = document.getElementById("r").innerHTML.toString().toUpperCase();
        document.getElementById("t").innerHTML = document.getElementById("t").innerHTML.toString().toUpperCase();
        document.getElementById("y").innerHTML = document.getElementById("y").innerHTML.toString().toUpperCase();
        document.getElementById("u").innerHTML = document.getElementById("u").innerHTML.toString().toUpperCase();
        document.getElementById("i").innerHTML = document.getElementById("i").innerHTML.toString().toUpperCase();
        document.getElementById("o").innerHTML = document.getElementById("o").innerHTML.toString().toUpperCase();
        document.getElementById("p").innerHTML = document.getElementById("p").innerHTML.toString().toUpperCase();
        document.getElementById("a").innerHTML = document.getElementById("a").innerHTML.toString().toUpperCase();
        document.getElementById("s").innerHTML = document.getElementById("s").innerHTML.toString().toUpperCase();
        document.getElementById("d").innerHTML = document.getElementById("d").innerHTML.toString().toUpperCase();
        document.getElementById("f").innerHTML = document.getElementById("f").innerHTML.toString().toUpperCase();
        document.getElementById("g").innerHTML = document.getElementById("g").innerHTML.toString().toUpperCase();
        document.getElementById("h").innerHTML = document.getElementById("h").innerHTML.toString().toUpperCase();
        document.getElementById("j").innerHTML = document.getElementById("j").innerHTML.toString().toUpperCase();
        document.getElementById("k").innerHTML = document.getElementById("k").innerHTML.toString().toUpperCase();
        document.getElementById("l").innerHTML = document.getElementById("l").innerHTML.toString().toUpperCase();
        document.getElementById("z").innerHTML = document.getElementById("z").innerHTML.toString().toUpperCase();
        document.getElementById("x").innerHTML = document.getElementById("x").innerHTML.toString().toUpperCase();
        document.getElementById("c").innerHTML = document.getElementById("c").innerHTML.toString().toUpperCase();
        document.getElementById("v").innerHTML = document.getElementById("v").innerHTML.toString().toUpperCase();
        document.getElementById("b").innerHTML = document.getElementById("b").innerHTML.toString().toUpperCase();
        document.getElementById("n").innerHTML = document.getElementById("n").innerHTML.toString().toUpperCase();
        document.getElementById("m").innerHTML = document.getElementById("m").innerHTML.toString().toUpperCase();
        document.getElementById("caps").value = "yes";
      }
   }}>
     <button type='submit'>Capslock [A]</button>
   </form>
</div>

     </React.StrictMode>
  );
}
async function sendThatKey(key) {
  axios.post(`${host}/program/press/a/key`, {
    password: `${password}`,
    key: key
  })
  .then(function (response) {
    console.log(response);
  })
  .catch(function (error) {
    console.log(error);
  });
} 
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
